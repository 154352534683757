@import "common.scss";

.login-form {
    .content {
        height: 580px;
        padding: 30px;

        h1 {
            font-size: 30px;
            margin-bottom: 50px;
            text-align: center;
        }
    }

    .button-heading {
        .edit-button-with-label {
            display: flex;
            flex-direction: column;
            margin-left: auto;
            align-items: flex-end;

            span {
                color: #ffffffc7;
                margin-bottom: 3px;
                white-space: nowrap;
            }
        }
    }
     
    form {
        max-width: 500px;
        margin-top: 15px;
      }
      
      .welcome-text {
          font-size: 13px;
        margin-top: 20px;
        margin-bottom: 0;
      }
      
      label {
        margin-bottom: 5px;
        height: 22px;

        font-family: 'Assistant';
        font-size: 13px;
        text-transform: uppercase;
        color: #666;
        margin-bottom: 0;
      }

      .toggle-label {
        margin-left: 10px;
        font-family: unset;
        text-transform: unset;
        font-size: unset;  
      }
      
      input {
        margin-bottom: 20px;
        padding: 10px;
        border-radius: 3px;
        border: 1px solid #777;
      }
      
      input.error {
        border-color: red;
      }
      
      .input-feedback {
        color: rgb(235, 54, 54);
        font-size: 12px;
        margin-top:-17px;
        margin-bottom: 20px;
      }

      
      .input-feedback.password{
        margin-top:0px;
      }
      
      .login-button {
        margin-top: 40px;  
        margin-bottom: 40px;
        @include confirm-button;
      }

      .cancel-button {
        @include cancel-button;
      }

      .forgot-pw-button {
            margin-left: auto;
            color: var(--action-color);
            &:hover {
                text-decoration: underline;
            }
      }

      .showPasswordIcon {
        position: absolute;
        right: 0;
        margin-right: 10px; 
        margin-top:2%;
        font-size: 20px;
        z-index: 3;
      }

    &.user-invite {
        position: absolute;
        z-index: 10;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #fbfbfb;

        h2 {
            text-align: center;
            margin-bottom: 20px;
            font-size: 22px;
            margin-top: -20px;
        }

        p {
            font-size: 14px;
            color: #555;
        }

        .user-name {
            font-size: 16px;
            font-weight: bold;
            text-align: center;
        }

        .small-text {
            font-size: 12px;
            color: #555;
            text-align: center;

            .link {
                text-decoration: underline;
                &:hover {
                    cursor: pointer;
                }
            }
        }
    }  
}